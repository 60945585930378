<template>
  <div>
    <v-row class="mt-10 pl-3 pr-7" justify="space-between">
      <div>
        <p class="title">Informações do Plano</p>
      </div>
      <div>
        <v-btn class="text--primary" style="background: #d0d2d5" v-if="isDisabledFields" large @click="onClickEdit()">
          <v-icon class="mr-2">
            fas fa-edit
          </v-icon>
          Editar
        </v-btn>
        <v-btn class="text--white" :disabled="loadingFinancialGroups || loadingContracts || loadingSubContracts || loadingEligibilities || loadingPlans" color="primary" v-else large @click="saveDataInSessionStorage()">
          <v-icon class="mr-2">
            fas fa-save
          </v-icon>
          Salvar
        </v-btn>
      </div>
    </v-row>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row class="mt-10 pr-4">
        <v-col cols="12" md="6" lg="3">
          <label class="label">Grupo Financeiro</label>
          <v-autocomplete
            v-model="plan.financialGroupId"
            :items="financialGroups"
            item-text="name"
            item-value="id"
            placeholder="Grupo Financeiro"
            outlined
            disabled
            color="textPrimary"
            :loading="loadingFinancialGroups"
            :class="verifyFieldInArray('financialGroupId') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <label class="label">Contrato / Apólice</label>
          <v-autocomplete
            v-model="plan.contract"
            :items="contracts"
            item-value="id"
            item-text="text"
            :class="verifyFieldInArray('policy') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            placeholder="Número do contrato"
            outlined
            color="textPrimary"
            :loading="loadingContracts"
            disabled
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <label class="label">Sub-Contrato</label>
          <v-autocomplete
            v-model="plan.subcontract"
            :items="subContracts"
            item-text="text"
            item-value="id"
            :class="verifyFieldInArray('subContract') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            placeholder="Número do sub-contrato"
            outlined
            color="textPrimary"
            @input="loadEligibilities(); plan.eligibility = ''; plan.plan = ''"
            :loading="loadingSubContracts"
            :disabled="isDisabledFields"
            :success-messages="currentPlanInfo.subcontract !== plan.subcontract ? previousSubContractName : null"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <label class="label">Elegibilidade</label>
          <v-autocomplete
            :items="eligibilities"
            :class="verifyFieldInArray('contractEligibilityId') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            item-text="elegibility_id.name"
            item-value="id"
            v-model="plan.eligibility"
            :disabled="isDisabledFields"
            outlined
            placeholder="Selecione"
            color="textPrimary"
            @input="loadPlans(); plan.plan = ''"
            :loading="loadingEligibilities"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <label class="label">Plano</label>
          <v-autocomplete
            v-model="plan.plan"
            :items="plans"
            item-text="name"
            item-value="id"
            :class="verifyFieldInArray('plan') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            :disabled="isDisabledFields"
            placeholder="Selecione"
            outlined
            color="textPrimary"
            :loading="loadingPlans"
            @input="observableChangePlan()"
            :success-messages="previousPlanName"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <label class="label">Início de Vigência</label>
          <div class="d-flex flex-column">
            <v-text-field
              :class="verifyFieldInArray('startDate') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
              outlined
              :disabled="isDisabledFields"
              v-model="plan.validityDate"
              append-icon="fas fa-calendar-alt"
              placeholder="DD/MM/YYYY"
              v-mask="'##/##/####'"
              color="textPrimary"
              @input="saveValidatyDate(plan.validityDate)"
              :rules="plan.validityDate ? [rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid] : []"
              validate-on-blur
            />
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <label class="label">Motivo da alteração</label>
          <v-autocomplete
            v-model="plan.planChangeReasonCode"
            :items="planChangeReasons"
            :disabled="isDisabledFields"
            placeholder="Selecione"
            item-text="description"
            item-value="code"
            outlined
            color="textPrimary"
            clearable
            :class="verifyFieldInArray('planChangeReasonCode') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
            <label class="label">Código Familiar</label>
            <v-text-field
              outlined
              v-model="plan.familyCode"
              v-mask="`${ formatter.maskLimiter(10) }`"
              color="textPrimary"
              placeholder="Informe código familiar"
              :disabled="isDisabledFields || isDisabledFamilyCode"
              :class="verifyFieldInArray('familyCode') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <label class="label">Sequencial</label>
            <v-text-field
              outlined
              v-model="plan.familyCodeSequential"
              v-mask="`${ formatter.maskLimiter(5) }`"
              color="textPrimary"
              placeholder="Informe código sequencial"
              :disabled="isDisabledFields || isDisabledFamilyCodeSequential"
              :class="verifyFieldInArray('familyCodeSeq') ? 'alterationColorText alterationColorBorder mt-2' : 'mt-2'"
            />
          </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import lodash from 'lodash';
import PlanChangeReasonService from '@/services-http/sdi/PlanChangeReasonService';
import ChangedFieldsMixin from '@/shared/mixins/highlightedChangedFields/changedFieldsMixin';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';
import EligibilitiesMixin from '@/shared/mixins/sdi/eligibilitiesMixin';
import PlansMixin from '@/shared/mixins/sdi/plansMixin';

export default {
  name: 'PlanInformation',
  data: () => ({
    valid: true,
    isDisabledFields: true,
    plan: {
      contract: null,
      subcontract: null,
      eligibility: null,
      financialGroupId: null,
      plan: null,
      previousPlan: null,
      planChangeReasonCode: null,
      validityDate: null,
      familyCode: null,
      familyCodeSequential: null,
    },
    currentPlanInfo: {},
    financialGroups: [],
    contracts: [],
    subContracts: [],
    eligibilities: [],
    eligibilitiesIds: [],
    plans: [],
    planChangeReasons: [],
    loadingFinancialGroups: false,
    loadingContracts: false,
    loadingSubContracts: false,
    loadingEligibilities: false,
    loadingPlans: false,
    isCriticizedCarrier: false,
    isCriticizedBroker: false,
    isEdit: false,
    isDisabledFamilyCode: false,
    isDisabledFamilyCodeSequential: false,
    isMounted: true,

    subContractNumber: null,
    previousSubContract: null,
    previousSubContractNumber: null,
    previousSubContractName: '',
    previousPlanName: '',
    isFreeMovement: null,
    financialGroupData: null,
    contractData: null,
    holderAdmissionDate: null,
    holderAlterationSessionStorage: {},
    dependentAlterationSessionStorage: {},
    movementRecord: {},
  }),

  mixins: [
    FinancialGroupsMixin,
    ContractsMixin,
    EligibilitiesMixin,
    PlansMixin,
    ChangedFieldsMixin,
  ],

  async mounted() {
    this.verifyTypeOperationForMovement();
    await this.onClickEdit();
    this.loadPlanChangeReasons();
    await this.loadingContractedPlan();
  },

  props: { admissionDateHolder: { type: String }, changedFields: { type: Array } },

  methods: {
    verifyTypeOperationForMovement() {
      if (sessionStorage.getItem('isCriticizedBroker')) {
        this.isCriticizedBroker = true;
      }
      if (sessionStorage.getItem('isCriticizedCarrier')) {
        this.isCriticizedCarrier = true;
      }
      if (sessionStorage.getItem('isEdit')) {
        this.isEdit = true;
      }
    },
    async loadSessionStorage() {
      if (this.$route.query.freeMovement && (this.$route.query.freeMovement === 'true' || this.$route.query.freeMovement === true)) {
        this.isFreeMovement = true;
        await this.loadSessionStorageForFreeMovement();
      } else {
        await this.loadInformations();
      }
    },
    async loadInformations() {
      if (sessionStorage.getItem('holderAlteration')) {
        this.setHolderAlteration();
      } else if (sessionStorage.getItem('dependentAlteration')) {
        this.setDependentAlteration();
      } else if (sessionStorage.getItem('movementRecord')) {
        this.isCriticizedCarrier = true;
        this.mapBeneficiaryMovementRecord();
      }
    },
    setHolderAlteration() {
      this.holderAlterationSessionStorage = JSON.parse(sessionStorage.getItem('holderAlteration'));
      if (this.holderAlterationSessionStorage) {
        const dateHolderFormat = this.holderAlterationSessionStorage.startDate ? this.formatter.formatDate(this.holderAlterationSessionStorage.startDate) : null;
        this.plan = {
          financialGroupId: this.holderAlterationSessionStorage.financialGroupId ? Number(this.holderAlterationSessionStorage.financialGroupId) : null,
          contract: this.holderAlterationSessionStorage.contractId ? Number(this.holderAlterationSessionStorage.contractId) : null,
          subcontract: this.holderAlterationSessionStorage.subcontractId ? Number(this.holderAlterationSessionStorage.subcontractId) : null,
          eligibility: this.holderAlterationSessionStorage.eligibilityId ? Number(this.holderAlterationSessionStorage.eligibilityId) : null,
          plan: this.holderAlterationSessionStorage.planId ? Number(this.holderAlterationSessionStorage.planId) : null,
          validityDate: dateHolderFormat || null,
          familyCode: this.holderAlterationSessionStorage.memberFamilyCode,
          familyCodeSequential: this.holderAlterationSessionStorage.memberFamilyCodeSeq,
        };
        this.isDisabledFamilyCode = this.plan.familyCode !== null;
        this.isDisabledFamilyCodeSequential = this.plan.familyCodeSequential !== null;
        this.currentPlanInfo = lodash.cloneDeep(this.plan);
      }
    },
    setDependentAlteration() {
      this.dependentAlterationSessionStorage = JSON.parse(sessionStorage.getItem('dependentAlteration'));
      if (this.dependentAlterationSessionStorage) {
        const dateDependentFormat = this.dependentAlterationSessionStorage.startDate ? this.formatter.formatDate(this.dependentAlterationSessionStorage.startDate) : null;
        this.plan = {
          financialGroupId: this.dependentAlterationSessionStorage.financialGroupId ? Number(this.dependentAlterationSessionStorage.financialGroupId) : null,
          contract: this.dependentAlterationSessionStorage.contractId ? Number(this.dependentAlterationSessionStorage.contractId) : null,
          subcontract: this.dependentAlterationSessionStorage.subcontractId ? Number(this.dependentAlterationSessionStorage.subcontractId) : null,
          eligibility: this.dependentAlterationSessionStorage.eligibilityId ? Number(this.dependentAlterationSessionStorage.eligibilityId) : null,
          plan: this.dependentAlterationSessionStorage.planId ? Number(this.dependentAlterationSessionStorage.planId) : null,
          validityDate: dateDependentFormat || null,
        };
        this.currentPlanInfo = lodash.cloneDeep(this.plan);
      }
    },
    async mapBeneficiaryMovementRecord() {
      this.movementRecord = JSON.parse(sessionStorage.getItem('movementRecord'));
      if (this.movementRecord) {
        const dateFormat = this.movementRecord.startDate ? this.formatter.formatDate(this.movementRecord.startDate) : null;
        this.plan = {
          financialGroupId: this.movementRecord.financialGroupId ? Number(this.movementRecord.financialGroupId) : null,
          contract: this.movementRecord.contractId ? Number(this.movementRecord.contractId) : null,
          subcontract: this.movementRecord.subContractId ? Number(this.movementRecord.subContractId) : null,
          eligibility: this.movementRecord.contractEligibilityId ? Number(this.movementRecord.contractEligibilityId) : null,
          plan: this.movementRecord.plan ? Number(this.movementRecord.plan) : null,
          validityDate: dateFormat || null,
          planChangeReasonCode: this.movementRecord.planChangeReasonCode,
          familyCode: this.movementRecord.familyCode,
          familyCodeSequential: this.movementRecord.familyCodeSequential,
        };
        this.isDisabledFamilyCode = this.plan.familyCode !== null;
        this.isDisabledFamilyCodeSequential = this.plan.familyCodeSequential !== null;
        this.currentPlanInfo = lodash.cloneDeep(this.plan);
      }
    },
    async onClickEdit() {
      this.isDisabledFields = false;
      this.$emit('disabledPlanInformation', this.isDisabledFields);
    },
    async saveDataInSessionStorage() {
      if (this.$refs.form.validate()) {
        const alterationObject = JSON.parse(sessionStorage.getItem('alterationObject'));
        alterationObject.plan = this.plan;
        alterationObject.plan.previousPlan = this.currentPlanInfo.plan !== this.plan.plan ? this.currentPlanInfo.plan : null;
        alterationObject.previousSubContractNumber = this.currentPlanInfo.previousSubContractNumber !== this.subContractNumber ? this.currentPlanInfo.previousSubContractNumber : null;
        alterationObject.subContractNumber = this.subContractNumber;
        alterationObject.subcontract = this.plan.subcontract ? String(this.plan.subcontract) : null;
        const validityDateClone = this.plan.validityDate ? lodash.cloneDeep(this.plan.validityDate) : null;
        alterationObject.plan.validityDate = this.plan.validityDate ? this.formatter.formatDateBRtoString(this.plan.validityDate) : null;
        if (this.isFreeMovement) {
          alterationObject.contract = this.plan.contract;
          alterationObject.contractPolicy = this.currentPlanInfo.contractPolicy;
        }
        sessionStorage.setItem('alterationObject', JSON.stringify(alterationObject));
        this.plan.validityDate = lodash.cloneDeep(validityDateClone);
        this.isDisabledFields = true;
        this.$emit('disabledPlanInformation', this.isDisabledFields);
      }
    },
    async loadEligibilities() {
      if (!this.plan.subcontract) {
        this.plans = [];
        this.plan.plan = null;
      } else {
        await this.getEligibilitiesByContractAndSubcontracts(this.plan.contract, this.plan.subcontract, this.plan.eligibility);
        this.loadPlans();
      }
      this.observableChangeSubContract();
    },
    async loadPlans() {
      this.plans = [];
      if (this.plan.eligibility) {
        const eligibilityFound = this.eligibilities.find((eligibility) => eligibility.id === this.plan.eligibility);
        await this.getPlansByEligibilityId(eligibilityFound);
      } else if (this.plan.plan) {
        await this.getPlanByIdMixin(this.plan.plan);
      } else {
        await this.getPlansByAllEligibilities(this.eligibilitiesIds);
      }

      this.getCurrentPlanById();
    },
    async getCurrentPlanById() {
      if (this.isMounted) {
        if (this.currentPlanInfo.plan) {
          let planData = [];
          if (this.plans && this.plans.length > 0) {
            planData = this.plans;
            const planFound = planData.find((plan) => plan.id === planData[0].id);
            if (planFound) {
              this.planName = `${planFound.name}`;
              this.isMounted = false;
            }
          }
        }
      }
    },
    observableChangeSubContract() {
      if (this.subContracts && this.subContracts.length > 0) {
        const subContractFound = this.subContracts.find((element) => element.id === this.plan.subcontract);
        this.subContractNumber = subContractFound && subContractFound.subcontract_number ? subContractFound.subcontract_number : null;
      }
    },
    observableChangePlan() {
      this.previousPlanName = (this.currentPlanInfo.plan && this.plan.plan) && (this.currentPlanInfo.plan !== this.plan.plan) ? `Plano Atual: ${this.planName}` : null;
    },
    async loadSessionStorageForFreeMovement() {
      if (sessionStorage.getItem('planInfo')) {
        const planInfo = JSON.parse(sessionStorage.getItem('planInfo'));
        this.financialGroupData = planInfo.financialGroupSelected ? planInfo.financialGroupSelected : null;
        this.contractData = planInfo.contractSelected ? planInfo.contractSelected : null;
        this.plan.financialGroupId = this.financialGroupData.id ? this.financialGroupData.id : null;
        this.plan.contract = this.contractData.id ? this.contractData.id : null;
      } else if (sessionStorage.getItem('movementRecord')) {
        this.mapBeneficiaryMovementRecord();
      }
    },
    saveValidatyDate(validatyDate) {
      if (validatyDate && validatyDate.length === 10) {
        this.$emit('saveValidatyDate', validatyDate);
      }
    },
    loadPlanChangeReasons() {
      this.planChangeReasonService.FindAll().then((response) => {
        if (response && response.data.length > 0) {
          this.planChangeReasons = response.data;
          this.planChangeReasons.sort((a, b) => (a.description > b.description ? 1 : -1));
        }
      });
    },
    async loadingContractedPlan() {
      this.loadSessionStorage();
      this.getFinancialGroups();
      this.getContractById(this.plan.contract);
      this.setCurrentContract();
      await this.getSubContractsByContractId(this.plan.contract);
      this.setCurrentSubContract();
      await this.loadEligibilities();
      sessionStorage.setItem('contractedPlanCurrent', JSON.stringify(this.currentPlanInfo));
      this.saveDataInSessionStorage();
    },
    setCurrentContract() {
      const contractFound = this.contracts.find((element) => element.id === this.plan.contract);
      this.currentPlanInfo.contractPolicy = contractFound && contractFound.policy ? contractFound.policy : null;
    },
    setCurrentSubContract() {
      const subContractFound = this.subContracts.find((element) => element.id === this.plan.subcontract);
      this.currentPlanInfo.previousSubContractNumber = subContractFound && subContractFound.subcontract_number ? subContractFound.subcontract_number : null;
      this.previousSubContractName = subContractFound ? (`Sub-Contrato Atual: ${subContractFound.subcontract_number ? subContractFound.subcontract_number : '-'} - ${subContractFound.contract_owner_id && subContractFound.contract_owner_id.legal_name ? subContractFound.contract_owner_id.legal_name : '-'}`) : null;
    },
  },
  async created() {
    this.formatter = new Formatters();
    this.rule = new Rules();
    this.planChangeReasonService = new PlanChangeReasonService();
  },
};
</script>
